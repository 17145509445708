import React from 'react'
import { Link } from 'gatsby'
import logoFull from '../images/logo_full.png'
import 'font-awesome/css/font-awesome.css';

const Footer = ({ }) => (
    <div className="footer row" style={{margin:"0 10%"}}>

      <div className="footer-item col-md-3 col-sm-12 footer-logo order-sm-1">
        <Link to="/"><img src={logoFull}/></Link>
      </div>

      <div className="footer-item col-md-6 col-sm-12 order-md-2 order-sm-3">

        <div className="footer-contact">For project enquiries, please email us or give us a call:</div>

        <div className="footer-contact-details">
          <div>projects@effy.co.in</div>
          <div>Registered Business Name: Rayles and Roobie Technologies Pvt. Ltd.</div>
        </div>

        <div className="footer-copyright">
          &copy; 2018 effy. All rights reserved
        </div>

      </div>

      <div className="footer-item col-md-3 col-sm-12 footer-menu order-sm-2 order-md-3">
        <div className="footer-menu-link"><Link to="/team">Team</Link></div>
        <div className="footer-menu-link"><Link to="/about">Culture</Link></div>
        <div className="footer-menu-link"><Link to="/integrations">Work</Link></div>
        <div className="footer-menu-link"><Link to="/contact">Contact</Link></div>
        <div className="footer-menu-link"><Link to="https://docs.google.com/document/d/e/2PACX-1vQhQcrFnGvax6KlUbCsjiJwdRxHmPNgi1AIQmImQjek4EJr8WZl9Ct7Z73_DRA3N7tANQB_eYwE_uF_/pub">Privacy policy</Link></div>
        <div className="footer-menu-link"><Link to="https://docs.google.com/document/d/e/2PACX-1vQIkD86bXk2jU3cxzMdvzCyICnqNIEvBW64wRIofgtIYCJ8Ke4Em84F6NIz2ERSGdaOnTkw-pQGDfTM/pub">Terms & Conditions</Link></div>
        <div className="footer-menu-link"><Link to="https://docs.google.com/document/d/e/2PACX-1vTXzOFJEQwvdO6iDtkXfvvgV4SyoWvH6_5Fo7moH5uKYE27PTS85b7ACKsNAySIoRsoW36sdbhAe7X4/pub">Refund Policy</Link></div>
        <div className="footer-menu-link"><Link to="https://culturemonkey.io">CultureMonkey</Link></div>
      </div>

    </div>
)

export default Footer
