import React from 'react'
import { Link } from 'gatsby'
import logo from '../images/logo_black.png'
import logo_white from '../images/logo_white.png'
import classnames from 'classnames'

import 'font-awesome/css/font-awesome.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Header = ({ siteTitle, isHomepage }) => (
    <div style={{margin:"0 10%"}}>
    <nav className={classnames('navbar', 'navbar-expand-sm', 'navbar-light', 'navbar-fixed-top', 'fixed-top', { 'navbar-transparent': isHomepage }, {'navbar-padding' : isHomepage})} style={{backgroundColor: "rgba(255, 255, 255, 0.7)", position: 'absolute'}}>
      <div className="container-fluid">
        {isHomepage ?
          <Link to="/"><img src={logo_white} style={{width: '150px', margin:"30px 10px"}} alt="Effy"/></Link>
          :
          <Link to="/"><img src={logo} style={{width: '110px', margin:"0"}} alt="Effy"/></Link>
        }

        <div className="navbar-header">
          <button type="button" className="navbar-toggler navbar-toggle-button ml-auto" data-toggle="collapse" data-target="#menubar" aria-controls="navbarResponsive"
        aria-expanded="false" >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>

        <div className="navbar-collapse collapse" id="menubar">
          <ul className="nav navbar-nav navbar-right ml-auto">
            <li className="nav-item"><Link to="/about">Culture</Link></li>
            <li className="nav-item"><Link to="/team">Team</Link></li>
            <li className="nav-item"><Link to="/integrations">Work</Link></li>
            <li className="nav-item"><a target='_blank' href="http://blog.effy.co.in">Blog</a></li>
            <li className="nav-item"><Link to="/contact">Contact</Link></li>
          </ul>
        </div>

      </div>
    </nav>

    </div>
)

export default Header
