import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import metaLogo from '../images/effy-web-res-137.jpg'
import './layout.css'

import {isMobile} from 'react-device-detect';

const Layout = ({ children, isHomepage }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <title>effy | Creative software development for humans</title>
          <meta name="description" content="Humans of effy are happy, humble and responsible in the way we treat people. Way ahead, premium and minimalistic in the way we engineer solutions." />
          <meta name="image" content={metaLogo} />

          {/* OpenGraph tags */}
          <meta property="og:url" content="https://effy.co.in" />
          <meta property="og:title" content="effy | Creative software development for humans" />
          <meta property="og:description" content="Humans of effy are happy, humble and responsible in the way we treat people. Way ahead, premium and minimalistic in the way we engineer solutions." />
          <meta property="og:content" content="Humans of effy are happy, humble and responsible in the way we treat people. Way ahead, premium and minimalistic in the way we engineer solutions." />
          <meta property="og:image" content={metaLogo} />
          <meta property="og:image:width" content="520" />
          <meta property="og:image:height" content="265" />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="humansofeffy" />
          <meta name="twitter:title" content={data.site.siteMetadata.title} />
          <meta name="twitter:description" content="Humans of effy are happy, humble and responsible in the way we treat people. Way ahead, premium and minimalistic in the way we engineer solutions." />
          <meta name="twitter:image" content={metaLogo} />
        </Helmet>
        {
          isMobile
          ?
            ''
          :
          (<Header siteTitle={data.site.siteMetadata.title} isHomepage={isHomepage} />)
        }
        {children}
        {
          isMobile
          ?
            ''
          :
            (<Footer  />)
        }
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
